<template>
    <ActionBtn v-if="shouldDisplay" :js="item.js" :icon="item.buttonIcon" :js-context="form" :classes="item.cssClass">
        {{ text }}

        <i v-if="isAdmin && isMyProject(this.item)" @click.prevent.stop="navigateToSettings" class="fa fa-cog" />
    </ActionBtn>
</template>

<script>
import ActionBtn from "@Platon/components/extended/ActionBtn.vue"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import FormButtonMixin from "@Platon/components/form/controls/FormButtonMixin"

/**
 * @property {PlatonForm} form
 * @property {PlatonFormElement} item
 */
export default {
    name: "FormActionButton",
    components: { ActionBtn },

    mixins: [PermissionMixin, NavigationMixin, FormButtonMixin],

    inject: ["form"],

    props: {
        item: {}
    },

    data() {
        return {
            updateId: 0
        }
    },

    methods: {
        navigateToSettings() {
            this.navigateTo(
                `/forms/form_elements/${this.item.id}?_target=modal&form_name=${this.form.localRoute.params.name}`
            )
        },

        increaseUpdateId() {
            this.updateId++
        }
    },

    mounted() {
        this.form.$on("formSetData", this.increaseUpdateId)
    },

    beforeDestroy() {
        this.form.$off("formSetData", this.increaseUpdateId)
    },

    computed: {
        shouldDisplay() {
            if (typeof this.item.displayCondition === "function") {
                this.updateId // just access variable to trigger recalculation
                return this.item.displayCondition(this.form, this.form.dataHolder) !== false
            }

            return true
        }
    }
}
</script>
