export default {
	methods: {
		/**
		 * @param {string} title
		 */
		setTitle(title) {
			document.title = title
		}
	}
}
