import { Keys } from "@Platon/const"

const IconPickerDialog = () => import("../components/misc/IconPickerDialog.vue")

export default {
	methods: {
		/**
		 * @param {HTMLInputElement} element
		 * @param {function} cb
		 */
		injectIconPickerDialog(element, cb) {
			element.addEventListener("keydown", (/** @type KeyboardEvent */ ev) => {
				if (ev.ctrlKey && ev.keyCode === Keys.I) {
					this.$modal.show(
						IconPickerDialog,
						{
							onPick: (cls) => {
								cb && cb(cls)
							}
						},
						{
							height: "auto",
							width: "1000px",
							clickToClose: false
						}
					)
				}
			})
		},

		injectIconPickerToFormElement(dataField) {
			let element = this.elements.get(dataField)

			if (!element || !element.$el || typeof element.$el.querySelector !== "function") {
				return
			}

			let el = element.$el.querySelector("input")

			if (!el) return

			this.injectIconPickerDialog(el, (icon) => {
				this.formSetData(dataField, icon)
			})
		}
	}
}
