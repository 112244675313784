import { runScopeFn } from "@Platon/core/condition"

export default {
	methods: {
		/**
		 * @param {string} url
		 * @param {function?} callback
		 */
		injectJsFromUrl(url, callback) {
			this.$http.get(url).then((res) => {
				callback && callback(res.data)
			})
		},

		/**
		 * @param {string} js
		 * @param {Object} context
		 * @param {any[]} args
		 */
		injectScopeJs(js, context, args) {
			if (js) runScopeFn(js, context, args)
		}
	}
}
