export default {
	data() {
		return {
			/**
			 * @return Promise<boolean>
			 */
			async beforeCreate() {
				return true
			},

			/**
			 * @param {number} id
			 */
			async afterCreate(id) {},

			/**
			 * @return Promise<boolean>
			 */
			async beforeSave() {
				return true
			},

			/**
			 * @param {number} id
			 */
			async afterSave(id) {},

			/**
			 * @return Promise<boolean>
			 */
			async beforeEdit() {
				return true
			},

			/**
			 * @param {number} id
			 */
			async afterEdit(id) {},

			/**
			 * @return Promise<boolean>
			 */
			async beforeClone() {
				return true
			},

			async afterClone() {},

			/**
			 * @return Promise<boolean>
			 */
			async beforeDelete() {
				return true
			},

			async afterDelete() {},

			/**
			 * @return Promise<boolean>
			 */
			async beforeHardDelete() {
				return true
			},

			async afterHardDelete() {},

			/**
			 * @return Promise<boolean>
			 */
			async beforeRestore() {
				return true
			},

			async afterRestore() {},

			/**
			 * @param data Current data
			 */
			async onFormLoad(data) {}
		}
	}
}
